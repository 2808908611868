<template>
  <div class="delete-account-instructions">
    <div class="public-screen user-flipcar-screen middle-box animated fadeIn">
      <b-row class="justify-content-end">
        <img class="app-logo-sm" src="@/assets/images/flipcar_logo.png" alt="Logo"/>
      </b-row>

      <b-form @submit.prevent="onSubmit">
        <h2 class="m-t-xl m-b-md">{{ $t("user.flipcar.delete_account_instructions.title") }}</h2>
        <p><strong>{{ $t("user.flipcar.delete_account_instructions.steps") }}</strong></p>
        <ol>
          <li>{{ $t("user.flipcar.delete_account_instructions.step1") }}</li>
          <li>{{ $t("user.flipcar.delete_account_instructions.step2") }}</li>
        </ol>

        <generic-input
            label="common.email"
            v-model="email"
            type="email"
            :placeholder="$t('login.email')"
            required
        ></generic-input>

        <b-button class="m-b block full-width" type="submit" variant="primary">
          {{ $t('button.send') }}
        </b-button>
      </b-form>

      <div class="text-center">
        <hr>
        <template v-for="(link, index) in linkList">
          <span v-if="index > 0"> | </span>
          <router-link :to="link.to" target="_blank">{{ $t(link.name) }}</router-link>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import {sendDeleteAccountInstructions} from "@/services/users";
import GenericInput from "@/components/generic_input.vue";

export default {
  components: {GenericInput},
  data() {
    return {
      email: '',
      linkList: [
        {
          to: {name: "user_flipcar_privacy"},
          name: "common.privacy_policy",
        },
        {
          to: {name: "user_flipcar_terms"},
          name: "common.terms_of_use"
        },
        {
          to: {name: "user_flipcar_imprint"},
          name: "common.imprint"
        }
      ]
    }
  },
  methods: {
    onSubmit(event) {
      sendDeleteAccountInstructions(this.email).catch()
    },
  },
  computed: {}
}
</script>

<style scoped lang="scss">

</style>